import './App.css';
import SortingVisualizer from './components/SortingVisualizer'

function App() {
  return (
    <div className="App">
		  <SortingVisualizer></SortingVisualizer>
    </div>
  );
}

export default App;
